<template>
  <div class="container">
    <div v-if="!isProfileFetching && !profileError" class="window window_account">
      <div class="account__container">
        <h1 class="account__title">#{{profile.accountNumber}}</h1>
        <a @click.prevent="logout" class="account__logout" href="#">Logout</a>
      </div>

      <b-tabs class="account__tabs">
        <b-tab title="change password" active>
          <div class="account__container">
            <form class="account__form" @submit.prevent="onSubmit">
              <label class="account__input-wrapper" for="current-password">
                <input
                  class="account__input"
                  id="current-password"
                  :type="isPasswordVisible ? 'password' : 'text'"
                  placeholder="Current password"
                  v-model="oldPassword"
                  autocomplete="current-password"
                >
                <button type="button" tabindex="-1" class="account__hide-password" @click="togglePasswordVisibility">
                  <img src="@/assets/images/eye.svg" alt="hide password">
                </button>
              </label>
              <hr class="account__hr">
              <!--          <input class="account__input" type="password" placeholder="">-->
              <!--          <input class="account__input" type="password" placeholder="">-->
              <label class="account__input-wrapper" for="new-password">
                <input
                  class="account__input"
                  id="new-password"
                  type="password"
                  placeholder="New password"
                  v-model="newPassword"
                  autocomplete="new-password"
                  :type="isPasswordVisible ? 'password' : 'text'"
                >
                <button type="button" tabindex="-1" class="account__hide-password" @click="togglePasswordVisibility">
                  <img src="@/assets/images/eye.svg" alt="hide password">
                </button>
              </label>

              <div v-if="$v.newPassword.$error">
                <small v-if="!$v.newPassword.required" class="account__form-error">
                  The password field is empty. Please enter a strong password.
                </small>

                <small v-if="!$v.newPassword.minLength" class="account__form-error">
                  Password must contain at least 8 characters.
                </small>

                <small v-if="!$v.newPassword.notSameAsPassword" class="account__form-error">
                  Current password and new password cannot be the same
                </small>
              </div>

              <label class="account__input-wrapper" for="confirm-password">
                <input
                  class="account__input"
                  id="confirm-password"
                  type="password"
                  placeholder="Confirm password"
                  v-model="newPasswordConfirmation"
                  autocomplete="new-password"
                  :type="isPasswordVisible ? 'password' : 'text'"
                >
                <button type="button" class="account__hide-password" @click="togglePasswordVisibility">
                  <img src="@/assets/images/eye.svg" alt="hide password">
                </button>
              </label>

              <div v-if="$v.newPasswordConfirmation.$error">
                <small
                  v-if="!$v.newPasswordConfirmation.sameAsPassword"
                  class="account__form-error"
                >
                  Password and confirm password do not match
                </small>
              </div>

              <button class="account__save" type="submit" :disabled="isChangingPassword">Save</button>
            </form>
          </div>
        </b-tab>

        <b-tab title="subscription">
          <div class="account__container account__tab-subscription">
            <div v-if="subscription" class="account-subscription">
              <div class="row">
                <div class="col-lg-3 col-sm-6">
                  <p class="account-subscription__text">
                    <span class="account-subscription__text_bold">Subscription:</span>
                    <br>
                    {{subscription.title || '-'}}
                  </p>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <p class="account-subscription__text">
                    <span class="account-subscription__text_bold">Next billing date:</span>
                    <br>
                    {{subscription.nextBillingDate || '-'}}
                  </p>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <p class="account-subscription__text">
                    <span class="account-subscription__text_bold">Payment method:</span>
                    <br>
                    <span class="account-subscription__icon-card">
                      <img src="@/assets/images/card.svg" alt="card">
                    </span>
                    {{subscription.paymentAccount && subscription.paymentAccount.fullCardNumber || '-'}}
                  </p>
                </div>
                <div class="col-lg-2 col-sm-6">
                  <p class="account-subscription__text">
                    <span class="account-subscription__text_bold">Cost:</span>
                    <br>
                    {{getCostFormatted(subscription.cost)}}
                  </p>
                </div>
                 <div class="col-lg-1 col-sm-6 d-lg-flex my-auto">
                  <button
                    v-if="subscription.type !== 'free'"
                    @click="cancelSubscription"
                    :disabled="isCancellingSubscription"
                    class="account-subscription__delete">
                    <img v-if="!isCancellingSubscription" src="@/assets/images/trash.svg" alt="">
                    <b-spinner v-else small variant="dark"></b-spinner>
                  </button>
                </div>
              </div>
            </div>

            <div style="margin: 28px 0 0;" v-else>
              Subscriptions don't exists
            </div>

            <router-link
              class="account-subscription__link"
              :to="{name: 'choose-subscription'}">
              View all subscriptions
              <span>
                      <img src="@/assets/images/arrow-right_purple.svg" alt="arrow right">
              </span>
            </router-link>
            <button class="account__save account__save_subscription" type="submit">Save</button>
          </div>
        </b-tab>
      </b-tabs>


    </div>

    <div v-else-if="isProfileFetching" class="text-center">
      <b-spinner variant="primary"></b-spinner>
    </div>

    <div v-else-if="!isProfileFetching && profileError" class="text-center text-danger">
      {{profileError}}
    </div>
  </div>

</template>

<script>
  import {CANCEL_SUBSCRIPTION, CHANGE_PASSWORD, MODULE_NAME as PROFILE} from "@/store/modules/profile";
  import {LOGOUT_REQUEST, SIGNIN_REQUEST} from "@/store/modules/auth";
  import { required, sameAs, minLength, not } from 'vuelidate/lib/validators'
  import * as numeral from 'numeraljs'

  export default {
    name: 'Profile',
    data() {
      return {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
        isPasswordVisible: false,
      }
    },
    validations: {
      oldPassword: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(12),
        notSameAsPassword: not(sameAs('oldPassword'))
      },
      newPasswordConfirmation: {
        required,
        sameAsPassword: sameAs('newPassword')
      }
    },
    computed: {
      profileState() {
        return this.$store.state[PROFILE].profile
      },
      isProfileFetching() {
        return this.profileState.loading
      },
      profile() {
        return this.profileState.data
      },
      profileError() {
        return this.profileState.error
      },
      subscription() {
        return this.profile && this.profile.subscription
      },
      changePasswordState() {
        return this.$store.state[PROFILE].changePassword
      },
      isChangingPassword() {
        return this.changePasswordState.loading
      },
      changingPasswordError() {
        return this.changePasswordState.error
      },
      cancellingSubscriptionState() {
        return this.$store.state[PROFILE].cancelSubscription
      },
      isCancellingSubscription() {
        return this.cancellingSubscriptionState.loading
      },
    },
    mounted() {

    },
    methods: {
      async onSubmit() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }

        await this.$store.dispatch(CHANGE_PASSWORD, {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        })

        this.oldPassword = ''
        this.newPassword = ''
        this.newPasswordConfirmation = ''
      },
      logout() {
        this.$store.dispatch(LOGOUT_REQUEST)
      },
      getCostFormatted(cost) {
        return numeral(cost / 100).format('$0,0.00')
      },
      cancelSubscription() {
        this.$store.dispatch(CANCEL_SUBSCRIPTION, {id: this.subscription.id})
      },
      togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible
      },
    }
  }
</script>
